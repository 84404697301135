<template>
  <div class="mt-3">
    <base-modal class='modal-see-survey' v-if='isOpen' maxWidth="max-width: 700px;" maxHeight='max-height: 500px;' :isOpened='isOpen' @close='isOpen = false'>
      <template v-slot:header>
        <h3>Cadastro</h3>
      </template>

      <template v-slot:content>
        <add-update-new :readOnly="false" @close="close" @show-toast-response="showToastResponse"/>
      </template>
    </base-modal>

    <dialog-confirmation
      description="Você está prestes a excluir um tutorial. Tem certeza que deseja realizar esta ação?"
      :is-open="openDialog"
      @close="openDialog = false"
      @confirmed="deleteUpdateNew"
    />

    <b-card no-body>
      <b-tabs pills card>
        <b-tab @click="setBreadcrumbTabsName('Atualizações e novidades')" no-body title='Atualizações e novidades' class='p-3'>
          <b-overlay
            :show='loading'
            opacity='0.6'
            spinner-small
            rounded="lg"
            spinner-variant='primary'
            class='d-inline-block w-100'
          >
            <div class='d-flex justify-content-between mb-2'>
              <header-update-new @change-surveys="handleChangeUpdateNew"/>

              <base-button @click="show()" class='w-25'>
                <b>Incluir Atualização</b>
              </base-button>
            </div>
            <update-new-table
              :updateNews="updateNews"
              :updateNewsMeta="updateNewsMeta"
              @change-updateNews="handleChangeUpdateNew"
              @remove-log="setLogUpdateRemove"
            />
            <p class="text-center" v-if='updateNews.length < 1'>Nenhum item encontrado</p>
            <template v-slot:overlay>
              <div class="text-center loading">
                <b-spinner style="width: 2.5rem; height: 2.5rem;" variant='primary' label="Large Spinner"></b-spinner>
              </div>
            </template>
          </b-overlay>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('Eventos e lives')" no-body title='Eventos e lives'>
          <events/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import HeaderUpdateNew from '@/components/UpdateNew/Header'
import UpdateNewTable from '@/components/UpdateNew/Table'
import AddUpdateNew from '@/components/UpdateNew/AddUpdateNew'
import Events from '@/components/Events/Events'
import { mapActions, mapGetters } from 'vuex'
import DialogConfirmation from '@/components/DialogConfirmation.vue'


export default {
  name: 'UpdateNew',
  data(){
    return {
      breadcrumbTabsName: 'Atualizações e novidades',
      logUpdate: {},
      openDialog: false,
      loading: false,
      isOpen: false,
      openDialogModalSeeUpdateNew: false,
      logUpdateRemove: '',
    }
  },
  components: {
    HeaderUpdateNew,
    UpdateNewTable,
    DialogConfirmation,
    AddUpdateNew,
    Events,
  },
  computed: {
    ...mapGetters('updateNews', {
      updateNews: 'getUpdateNews',
      updateNewsMeta: 'getUpdateNewsMeta',
    }),
  },

  methods: {
    ...mapActions('updateNews', {
      handleGetNewsUpdates: 'handleGetNewsUpdates',
      handleGetUpdateLogByIndex: 'handleGetUpdateLogByIndex',
      handleDeleteUpdateNew: 'handleDeleteUpdateNew',
      resetUpdateNew: 'resetUpdateNew',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    setBreadcrumbTabsName(breadcrumbTabsName){
      this.breadcrumbTabsName = breadcrumbTabsName;
      const breadcrumb = {
        page: 'Atualizações e novidades',
        subPage: {
          name: 'Atualizações e novidades',
          active: false
        },
        tabs: [
          {
            name: this.breadcrumbTabsName,
            active: true
          }
        ]
      }
      this.setBreadcrumb(breadcrumb)
    },
    async showToastResponse(message) {

      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      await this.handleGetNewsUpdates();
      this.close()
    },
    async show(){
      this.isOpen = true;
      await this.resetUpdateNew()
    },
    close(){
      this.isOpen = false
    },
    setLogUpdateRemove(item){
      this.logUpdateRemove = item;
      this.openDialog = true;
    },
    async deleteUpdateNew(){
      const response = await this.handleDeleteUpdateNew(this.logUpdateRemove._id)
      let message = response.message
      let variant = 'success'

      if (response.errors) {
        message = response.errors
        variant = 'danger'
      }

      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      this.openDialog = false;
      await this.handleGetNewsUpdates({
        sortIn: 'desc'
      });
    },
    getUpdateLog(index){
      this.handleGetUpdateLogByIndex(index)
      this.openDialog = true
    },
    async handleChangeUpdateNew(payload){
      const params = {
        ...this.updateNewsMeta,
        ...payload
      }

      await this.handleGetNewsUpdates(params);
    },
  },
  async created(){
    this.loading = true;
    await this.handleGetNewsUpdates({
      sortIn: 'desc'
    });
    this.loading = false;
    this.setBreadcrumbTabsName('Atualizações e novidades')
  },
}
</script>

<style lang="scss" scoped>
.modal-see-survey {
  /deep/ header {
    padding: 0.5rem;

    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    max-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 1rem;

    .infos, .answers {
      display: flex;
      flex-direction: column;

      h3 {
        font-weight: bold;
      }
    }
  }

  /deep/ footer {
    display: flex;
    gap: 16px;
    padding: 1rem;
  }
}
.none-sublinhado{
  text-decoration: none;
}
.surveys-view {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
