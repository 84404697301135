<template>
  <div>
    <dialog-confirmation
      description="Você está prestes a excluir um evento. Tem certeza que deseja realizar esta ação?"
      :is-open="openDialog"
      @close="openDialog = false"
      @confirmed="deleteEvent"
    />

    <b-modal
      ref='event'
      size='lg' scrollable hide-footer
      :title="`${event._id ? 'Atualizar evento': 'Cadastrar evento'}`"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <div class='modal-list-create-promocode'>
        <base-input-text
          v-model='v$.event.title.$model'
          label='Titulo'
          placeholder='Digite o titulo'
        />

        <base-input-text
          class='mt-2'
          v-model='v$.event.description.$model'
          label='Descrição'
          placeholder='Digite o titulo'
        />

        <base-input-text
          class=''
          v-model='v$.event.link.$model'
          label='Link'
          placeholder='Digite o link'
        />
      </div>
      <div class='d-flex mb-2 mt-3'>
        <base-button
          @click='hide'
          class='mr-1 w-50'
          block
          danger
        >
          Cancelar
        </base-button>

        <b-overlay
          :show='addEvent'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block w-50'
        >
          <base-button
            v-if='event._id'
            success
            :disabled='v$.$invalid'
            @click='updateEvent()'
            block
          >
            <span>Atualizar</span>
          </base-button>

          <base-button
            v-else
            success
            :disabled='v$.$invalid'
            @click='registerEvent()'
            block
          >
            <span>Criar</span>
          </base-button>
        </b-overlay>
      </div>
    </b-modal>

    <div class='mb-3 mt-3  w-100 justify-content-end d-flex'>
      <base-button @click='modalCreateEvent()' class='w-25 mr-2'>
        <b>Criar evento</b>
      </base-button>
    </div>

    <div v-if='events && events.length > 0' class='mx-3 mb-4'>
      <section v-for='(event, i) in events' :key='i' class='d-flex w-100'>

        <section class='event my-1 d-flex w-100 justify-content-between'>
          <section class='w-100'>
            <section class='d-flex align-items-start mb-3'>
              <section class='infoLink'>
                <img width='50px' src='@/assets/images/infoLink.svg' />
              </section>

              <section class='containerTitleDescription'>
                <strong class='title'>{{ event.title }}</strong><br />
                <strong class='description'>{{ event.description }}</strong><br />
              </section>
            </section>

            <section class='w-100'>
              <section class='linkContainer d-flex'>
                <section class='iconeGoogleMeet'>
                  <img width='42px' src='@/assets/images/Icone_Google_Meet.svg' />
                </section>

                <section class='align-self-center'>
                  <a :href='event.link' target='_blank' class='link'>{{ event.link }}</a><br />
                </section>
              </section>
            </section>
          </section>

          <section class='container-edit'>
            <b-button variant='success' class='d-flex mr-2 align-items-center py-2' @click='modalEditEvent(event._id)'>
              <b-icon font-scale='1' class='mr-1 ' icon='pencil-square'></b-icon>
              Editar
            </b-button>

            <b-button variant='danger' class='d-flex align-items-center py-2' @click='openModalDelete(event._id)'>
              <b-icon font-scale='1' class='mr-1' icon='trash-fill'></b-icon>
              Excluir
            </b-button>


            <b-form-checkbox @change='activeEvent()' size='lg' class='my-3 ml-2 checkbox-event' v-model='selectedEvent' :value='event._id'
                             name='check-button' switch>
            </b-form-checkbox>
          </section>
        </section>
      </section>
    </div>
    <h3 v-else >Não há eventos cadastrados</h3>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/validators'
import DialogConfirmation from '@/components/DialogConfirmation.vue'

export default {
  name: 'Events',
  setup() {
    return { v$: useVuelidate() }
  },
  validations: {
    event: {
      title: { required },
      description: { required },
      link: { required },
      active: {}
    }
  },
  components: {
    DialogConfirmation
  },
  computed: {
    ...mapGetters('event', {
      event: 'getEvent'
    })

  },
  data() {
    return {
      addEvent: false,
      selectedEvent: null,
      status: false,
      events: [],
      eventDeleteId: '',
      openDialog: false
    }
  },
  async mounted() {
    await this.listEvents()
  },
  methods: {
    ...mapActions('event', {
      handleCreateEvent: 'handleCreateEvent',
      handleUpdateEvent: 'handleUpdateEvent',
      handlDeleteEvent: 'handlDeleteEvent',
      handlGetEvents: 'handlGetEvents',
      handlGetEvent: 'handlGetEvent',
      handlGetEventActive: 'handlGetEventActive',
      handleEnableOrDisableEvent: 'handleEnableOrDisableEvent',
      resetEvent: 'resetEvent'
    }),
    async activeEvent() {
      try {
        const { data, status } = await this.handleEnableOrDisableEvent(this.selectedEvent)

        let message = data.message
        let variant = 'danger'

        if (status === 200) {
          message = data.message
          variant = 'success'
        }

        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.hide()
        await this.resetEvent()
        await this.listEvents()
        this.openDialog = false;
        await this.handlGetEventActive();
      } catch (e) {

      } finally {
        this.addEvent = false;
      }
    },
    show() {
      this.$refs['event'].show()
    },

    hide() {
      this.$refs['event'].hide()
    },

    async deleteEvent() {
      try {
        const { data, status } = await this.handlDeleteEvent(this.eventDeleteId)
        let message = data.message
        let variant = 'danger'

        if (status === 200) {
          message = data.message
          variant = 'success'
        }

        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.hide()
        await this.resetEvent()
        await this.listEvents()
        this.openDialog = false;
      } catch (e) {

      } finally {
        this.addEvent = false;
      }
    },

    openModalDelete(eventId){
      this.eventDeleteId = eventId
      this.openDialog = true;
    },

    async modalEditEvent(eventId) {
      await this.handlGetEvent(eventId)
      this.show()
    },

    async modalCreateEvent() {
      await this.resetEvent()
      this.show()
    },

    async registerEvent() {
      try {
        this.addEvent = true;
        const { data, status } = await this.handleCreateEvent()

        let message = data.message
        let variant = 'danger'

        if (status === 201) {
          message = data.message
          variant = 'success'
        }

        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.hide()
        await this.resetEvent()
        await this.listEvents()
      } catch (e) {

      } finally {
        this.addEvent = false;
      }
    },

    async updateEvent() {
      try {
        this.addEvent = true;
        const { data, status } = await this.handleUpdateEvent()

        let message = data.message
        let variant = 'danger'

        if (status === 200) {
          message = data.message
          variant = 'success'
        }

        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.hide()
        await this.resetEvent()
        await this.listEvents()
      } catch (e) {

      } finally {
        this.addEvent = false;
      }
    },

    async listEvents() {
      const { events } = await this.handlGetEvents()
      this.events = events
      const eventActive = this.events.find((x) => x.active)
      this.selectedEvent = eventActive ? eventActive._id : null
    }
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.checkbox-event{
  cursor:pointer;
}
.event {
  margin-top: -10px;
  width: 100%;
  background: #022245;
  box-shadow: 0 0 2px rgba(14, 31, 53, 0.12);
  border-radius: 5px;
  padding: 7px 15px;
}

.infoLink {
  background: rgba(4, 168, 243, 0.1);
  border-radius: 50%;
  padding: 5px;
  margin-right: 5px;
}

.containerTitleDescription {
  border-left: 2px solid #ECF0F3;
  padding-left: 5px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #ECF0F3;
}

.description {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #808080;
}

.linkContainer {
  width: 100%;
  background: #CBD63E;
  border-radius: 5px;
  height: 43px;
}

.iconeGoogleMeet {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(14, 31, 53, 0.12);
  border-radius: 5px 0 0 5px;
  height: 100%;
  width: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #022245;
  padding-left: 15px;
}

.container-edit {
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: end;
}
</style>
