<template>
  <div class='surveys-table'>
    <base-modal class='modal-see-survey' v-if='isOpen' maxWidth='max-width: 700px;' maxHeight='max-height: 500px;'
                :isOpened='isOpen' @close='isOpen = false'>
      <template v-slot:header>
        <h3>Cadastro</h3>
      </template>

      <template v-slot:content>
        <add-update-new :readOnly='false' :log='logUpdate' @show-toast-response='showToastResponse' />
      </template>
    </base-modal>

    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      ref='users-like-notification'
      size='xl'
      scrollable
      hide-footer
      title='Usuários que deram like na notificação'
    >
      <table>
        <thead>
        <tr>
          <th>Usuário</th>
          <th>Data</th>
        </tr>
        </thead>
        <tbody v-for='(item, i) in usersLikedNewUpdate' :key='i'>
        <tr>
          <td>{{item.users.username}}</td>
          <td>{{ item.createdAt }}</td>
        </tr>
        </tbody>
      </table>

      <div class='mt-3' v-if='usersLikedNewUpdateMeta.total > perPage'>
        <b-pagination
          align="center"
          pills
          v-model='skipLike'
          :total-rows='usersLikedNewUpdateMeta.total'
          :per-page='perPage'
          aria-controls='my-table'
        ></b-pagination>
      </div>
    </b-modal>

    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      ref='users-dislike-notification'
      size='xl'
      scrollable
      hide-footer
      title='Usuários que deram dislike na notificação'
    >
      <table>
        <thead>
        <tr>
          <th>Usuário</th>
          <th>Data</th>
        </tr>
        </thead>
        <tbody v-for='(item, i) in usersDisLikeNewUpdate' :key='i'>
        <tr>
          <td>{{item.users.username}}</td>
          <td>{{ item.createdAt }}</td>
        </tr>
        </tbody>
      </table>

      <div class='mt-3' v-if='usersDisLikeNewUpdateMeta.total > perPage'>
        <b-pagination
          align="center"
          pills
          v-model='skipDisLike'
          :total-rows='usersDisLikeNewUpdateMeta.total'
          :per-page='perPage'
          aria-controls='my-table'
        ></b-pagination>
      </div>
    </b-modal>

    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      ref='users-read-notification'
      size='xl'
      scrollable
      hide-footer
      title='Usuários que leram a notificação'
    >
      <table>
        <thead>
        <tr>
          <th>Usuário</th>
          <th>Data</th>
        </tr>
        </thead>
        <tbody v-for='(item, i) in usersReadNewUpdate' :key='i'>
        <tr>
          <td>{{item.users.username}}</td>
          <td>{{ item.createdAt }}</td>
        </tr>
        </tbody>
      </table>

      <div class='mt-3' v-if='usersReadNewUpdateMeta.total > perPage'>
        <b-pagination
          align="center"
          pills
          v-model='skipRead'
          :total-rows='usersReadNewUpdateMeta.total'
          :per-page='perPage'
          aria-controls='my-table'
        ></b-pagination>
      </div>
    </b-modal>

    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      ref='users-saw-notification'
      size='xl'
      scrollable
      hide-footer
      title='Usuários que viram a notificação'
    >
      <table>
        <thead>
        <tr>
          <th>Usuário</th>
          <th>Data</th>
        </tr>
        </thead>
        <tbody v-for='(item, i) in usersSawNewUpdate' :key='i'>
        <tr>
          <td>{{item.users.username}}</td>
          <td>{{ item.createdAt }}</td>
        </tr>
        </tbody>
      </table>

      <div class='mt-3' v-if='usersSawNewUpdateMeta.total > perPage'>
        <b-pagination
          align="center"
          pills
          v-model='skipSaw'
          :total-rows='usersSawNewUpdateMeta.total'
          :per-page='perPage'
          aria-controls='my-table'
        ></b-pagination>
      </div>
    </b-modal>

    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      ref='users-comment-notification'
      size='xl'
      scrollable
      hide-footer
      title='Usuários que fizeram comentário na notificação'
    >
      <table>
        <thead>
        <tr>
          <th>Usuário</th>
          <th>Comentário</th>
          <th>Data</th>
        </tr>
        </thead>
        <tbody v-for='(item, i) in usersCommentsNewUpdate' :key='i'>
        <tr>
          <td>{{item.users.username}}</td>
          <td>{{item.comment}}</td>
          <td>{{ item.createdAt }}</td>
        </tr>
        </tbody>
      </table>

      <div class='mt-3' v-if='usersNewUpdateMeta.total > perPage'>
        <b-pagination
          align="center"
          pills
          v-model='skipComments'
          :total-rows='usersNewUpdateMeta.total'
          :per-page='perPage'
          aria-controls='my-table'
        ></b-pagination>
      </div>
    </b-modal>

    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      ref='userDoNotShowNewUpdate'
      size='xl'
      scrollable
      hide-footer
      title='Usuários que não querem ver a notificação na tela inicial'
    >
      <table>
        <thead>
        <tr>
          <th>Usuário</th>
          <th>Data</th>
        </tr>
        </thead>
        <tbody v-for='(item, i) in userDoNotShowNewUpdate' :key='i'>
        <tr>
          <td>{{item.users.username}}</td>
          <td>{{ item.createdAt }}</td>
        </tr>
        </tbody>
      </table>

      <div class='mt-3' v-if='userDoNotShowNewUpdateMeta.total > perPage'>
        <b-pagination
          align="center"
          pills
          v-model='skipDoNotShow'
          :total-rows='userDoNotShowNewUpdateMeta.total'
          :per-page='perPage'
          aria-controls='my-table'
        ></b-pagination>
      </div>
    </b-modal>

    <base-table
      v-if='updateNews && updateNewsMeta'
      :columns='columns'
      :data='updateNews'
      :total-items='updateNewsMeta.total'
      :start-items='updateNewsMeta.skip + 1'
      :max-items='updateNewsMeta.limit'
      @set-page='handleChangeUpdateNew'
    >
      <template v-slot:body-user='row'>
        {{ row.item.user.username ? row.item.user.username : '' }}
      </template>

      <template v-slot:body-actions='row'>
        <i
          class='fas fa-pen fa-lg'
          @click='seeMoreUser(row.item._id)'
        />

        <i
          class='fas fa-trash fa-lg'
          @click="$emit('remove-log', row.item)"
        />

        <i
          class='fas fa-thumbs-up fa-lg'
          @click='showUsersLikeNewUpdate(row.item._id)'
        />

        <i
          class='fas fa-thumbs-down fa-lg'
          @click='showUsersDisLikeNewUpdate(row.item._id)'
        />

        <i
          class='fas fa-comment-medical fa-lg'
          @click='showUsersCommentsNewUpdate(row.item._id)'
        />

        <i
          class='fas fa-eye fa-lg'
          @click='showUsersSawNewUpdate(row.item._id)'
        />

        <i
          class='fas fa-book fa-lg'
          @click='showUsersReadNewUpdate(row.item._id)'
        />

        <i
          class='fas fa fa-times-circle fa-lg'
          @click='showUserDoNotShowNewUpdate(row.item._id)'
        />
      </template>
    </base-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AddUpdateNew from '@/components/UpdateNew/AddUpdateNew'
export default {
  name: 'UpdateNewTable',
  props: {
    updateNews: { type: Array, require: true },
    updateNewsMeta: { type: Object, require: true }
  },
  async created() {
  },
  data() {
    return {
      perPage: 10,
      logUpdate: '',
      skipLike: 1,
      skipDisLike: 1,
      skipRead: 1,
      skipSaw: 1,
      skipComments: 1,
      skipDoNotShow: 1,

      usersLikedNewUpdateId: null,
      usersDisLikeNewUpdateId: null,
      usersReadNewUpdateId: null,
      usersSawNewUpdateId: null,
      usersCommentsNewUpdateId: null,
      userDoNotShowNewUpdateId: null,

      usersLikedNewUpdate: [],
      usersLikedNewUpdateMeta: {},

      usersDisLikeNewUpdate: [],
      usersDisLikeNewUpdateMeta: {},

      usersReadNewUpdate: [],
      usersReadNewUpdateMeta: {},

      usersSawNewUpdate: [],
      usersSawNewUpdateMeta: {},

      usersCommentsNewUpdate: [],
      usersNewUpdateMeta: {},

      userDoNotShowNewUpdate: [],
      userDoNotShowNewUpdateMeta: {},
      openDialog: false,
      isOpen: false,
      columns: [
        { key: 'title', value: 'Título' },
        { key: 'registrationDate', value: 'Data' },
        { key: 'actions', value: 'Ações' }
      ],
      columnsLike: ['user', 'createdAt']
    }
  },
  components: {
    AddUpdateNew
  },
  methods: {
    ...mapActions('updateNews', {
      handleGetNewsUpdates: 'handleGetNewsUpdates',
      handleGetUsersLikedUpdateNew: 'handleGetUsersLikedUpdateNew',
      handleGetUsersDisLikeNewUpdate: 'handleGetUsersDisLikeNewUpdate',
      handleGetUsersReadNewUpdate: 'handleGetUsersReadNewUpdate',
      handleGetUsersSawNewUpdate: 'handleGetUsersSawNewUpdate',
      handleGetUsersCommentsNewUpdate: 'handleGetUsersCommentsNewUpdate',
      handleGetUserDoNotShowNewUpdate: 'handleGetUserDoNotShowNewUpdate',
    }),
    async showUsersLikeNewUpdate(newUpdateId) {
      try {
        this.skipLike = 1;
        this.usersLikedNewUpdateId = newUpdateId
        const { usersLikeNewUpdate, meta } = await this.handleGetUsersLikedUpdateNew(
          { newUpdateId: this.usersLikedNewUpdateId }
        )
        this.usersLikedNewUpdate = usersLikeNewUpdate
        this.usersLikedNewUpdateMeta = meta
        this.$refs['users-like-notification'].show()
      } catch (e) {

      }
    },

    async showUsersDisLikeNewUpdate(newUpdateId) {
      try {
        this.skipDisLike = 1;
        this.usersDisLikeNewUpdateId = newUpdateId
        const { usersDisLikedNewUpdate, meta } = await this.handleGetUsersDisLikeNewUpdate(
          { newUpdateId: this.usersDisLikeNewUpdateId }
        )
        this.usersDisLikeNewUpdate = usersDisLikedNewUpdate
        this.usersDisLikeNewUpdateMeta = meta
        this.$refs['users-dislike-notification'].show()
      } catch (e) {

      }
    },

    async showUsersReadNewUpdate(newUpdateId) {
      try {
        this.skipRead = 1;
        this.usersReadNewUpdateId = newUpdateId
        const { userReadNewUpdate, meta } = await this.handleGetUsersReadNewUpdate(
          { newUpdateId: this.usersReadNewUpdateId }
        )
        this.usersReadNewUpdate = userReadNewUpdate
        this.usersReadNewUpdateMeta = meta
        this.$refs['users-read-notification'].show()
      } catch (e) {

      }
    },

    async showUserDoNotShowNewUpdate(newUpdateId) {
      try {
        this.skipRead = 1;
        this.userDoNotShowNewUpdateId = newUpdateId
        const { userDoNotShowNewUpdate, meta } = await this.handleGetUserDoNotShowNewUpdate(
          { newUpdateId: this.userDoNotShowNewUpdateId }
        )
        this.userDoNotShowNewUpdate = userDoNotShowNewUpdate
        this.userDoNotShowNewUpdateMeta = meta
        this.$refs['userDoNotShowNewUpdate'].show()
      } catch (e) {

      }
    },

    async showUsersSawNewUpdate(newUpdateId) {
      try {
        this.skipSaw = 1;
        this.usersSawNewUpdateId = newUpdateId
        const { userSawNewUpdate, meta } = await this.handleGetUsersSawNewUpdate(
          { newUpdateId: this.usersSawNewUpdateId }
        )
        this.usersSawNewUpdate = userSawNewUpdate
        this.usersSawNewUpdateMeta = meta
        this.$refs['users-saw-notification'].show()
      } catch (e) {

      }
    },

    async showUsersCommentsNewUpdate(newUpdateId) {
      try {
        this.skipComments = 1;
        this.usersCommentsNewUpdateId = newUpdateId
        const { userCommentNewUpdate, meta } = await this.handleGetUsersCommentsNewUpdate(
          { newUpdateId: this.usersCommentsNewUpdateId }
        )
        this.usersCommentsNewUpdate = userCommentNewUpdate
        this.usersNewUpdateMeta = meta
        this.$refs['users-comment-notification'].show()
      } catch (e) {

      }
    },

    async showToastResponse(message) {
      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      await this.handleGetNewsUpdates()
      this.isOpen = false
    },

    async seeMoreUser(_id) {
      this.logUpdate = _id
      this.isOpen = true
    },

    handleChangeUpdateNew(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.updateNewsMeta.limit)
      }
      this.$emit('change-updateNews', params)
    },
  },
  watch: {
    async skipLike() {
      const params = {
        skip: ((this.skipLike - 1) * this.usersLikedNewUpdateMeta.limit),
        newUpdateId: this.usersLikedNewUpdateId
      }
      const { usersLikeNewUpdate } = await this.handleGetUsersLikedUpdateNew(params)
      this.usersLikedNewUpdate = usersLikeNewUpdate
    },
    async skipDisLike() {
      const params = {
        skip: ((this.skipDisLike - 1) * this.usersDisLikeNewUpdateMeta.limit),
        newUpdateId: this.usersLikedNewUpdateId
      }
      const { usersDisLikedNewUpdate } = await this.handleGetUsersDisLikeNewUpdate(params)
      this.usersDisLikeNewUpdate = usersDisLikedNewUpdate
    },
    async skipRead() {
      const params = {
        skip: ((this.skipRead - 1) * this.usersReadNewUpdateMeta.limit),
        newUpdateId: this.usersLikedNewUpdateId
      }
      const { userReadNewUpdate } = await this.handleGetUsersReadNewUpdate(params)
      this.usersReadNewUpdate = userReadNewUpdate
    },
    async skipSaw() {
      const params = {
        skip: ((this.skipSaw - 1) * this.usersSawNewUpdateMeta.limit),
        newUpdateId: this.usersLikedNewUpdateId
      }
      const { userSawNewUpdate } = await this.handleGetUsersSawNewUpdate(params)
      this.usersSawNewUpdate = userSawNewUpdate
    },
    async skipComments() {
      const params = {
        skip: ((this.skipComments - 1) * this.usersNewUpdateMeta.limit),
        newUpdateId: this.usersLikedNewUpdateId
      }
      const { userCommentNewUpdate } = await this.handleGetUsersCommentsNewUpdate(params)
      this.usersCommentsNewUpdate = userCommentNewUpdate
    },
    async skipDoNotShow() {
      const params = {
        skip: ((this.skipDoNotShow - 1) * this.userDoNotShowNewUpdateMeta.limit),
        newUpdateId: this.userDoNotShowNewUpdateId
      }
      const { userDoNotShowNewUpdate } = await this.handleGetUserDoNotShowNewUpdate(params)
      this.userDoNotShowNewUpdate = userDoNotShowNewUpdate
    }
  }
}
</script>

<style lang='scss' scoped>
.modal-see-survey {
  /deep/ header {
    padding: 0.5rem;

    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    max-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 1rem;

    .infos, .answers {
      display: flex;
      flex-direction: column;

      h3 {
        font-weight: bold;
      }
    }
  }

  /deep/ footer {
    display: flex;
    gap: 16px;
    padding: 1rem;
  }
}

.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  background: var(--blue);
  color: white;
}
</style>
